import React, { useEffect, useMemo, useRef, useState } from "react";
import { getCategories } from "../Categories/Services.tsx";
import { Category } from "../Categories/Categories.tsx";
import { fetchMedia } from "../../Utils/Connection.tsx";
import { getCreators } from "../Creators/Services.tsx";
import Spinner from "../../Components/Spinner.tsx";
import SuccessModal from "../../Components/SuccessModal.tsx";
import ErrorModal from "../../Components/ErrorModal.tsx";
import ProgressBar from "../../Components/ProgressBar.tsx";

const UploadDataPage = () => {
  const [categories, setCategories] = useState([]);
  const [creators, setCreators] = useState([]);
  const [selectedImages, setSelectedImages] = useState<any>([]);
  const [selectedCreator, setSelectedCreator] = useState("");
  const [isPremium, setIsPremium] = useState<boolean>(false);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [progress, setProgress] = useState(0);
  const [tags, setTags] = useState(""); // "tag1, tag2, tag3, ..."
  const [category, setCategory] = useState(""); // "category_id"

  const selectInputRef = useRef<HTMLInputElement>(null);

  const fetchCat = async () => {
    const cat = await getCategories();
    setCategories(cat);
    const creators = await getCreators();
    setCreators(creators);
  };

  const onSelectCategory = (e) => {
    const selectedOptions = Array.from(e.target.selectedOptions);
    setCategory(selectedOptions.map((option: any) => option?.value).join(","));
  };
  const onSelectCreator = (e) => {
    setSelectedCreator(e.target.value);
  };

  useEffect(() => {
    fetchCat();
  }, []);

  const onSubmit = async () => {
    const body = {
      category: category
        .split(",")
        .filter((item) => item)
        .map((item) => item.trim()),
        // make the tage Capitalized
      tags: tags.split(",").map((tag) => tag.trim().charAt(0).toUpperCase() + tag.trim().slice(1)),
      images: selectedImages,
    };

    if (!body.category.length) {
      alert("Please select a category");
      return;
    }
    if (!body.tags.length) {
      alert("Please add tags");
      return;
    }
    if (!selectedCreator) {
      alert("Please select a creator");
      return;
    }

    const formData = new FormData();
    formData.append("categories", JSON.stringify(body.category));
    formData.append("tags", JSON.stringify(body.tags));
    formData.append("creator", selectedCreator);
    formData.append("isPremium", isPremium.toString());

    body.images.forEach((image) => {
      formData.append("images", image);
    });
    setLoading(true);
    try {
      const res = await fetchMedia("wallpapers/upload", formData, (event) => {
        const percentage = Math.round((event.loaded * 100) / event.total);
        setProgress(percentage);
      });

      if (res) {
        setSelectedImages([]);
        setIsPremium(false);
        
        setSuccessModal(true);
      } else {
        setError(true);
      }
    } catch (error) {
      setError(true);
    }

    setLoading(false);
   
    setProgress(0); // Reset progress after completion
  };

  const imageComponent = useMemo(() => {
    return selectedImages.map((item: File) => (
      <div
        key={item.name}
        className="w-full gap-1 overflow-hidden bg-[#111418] @[480px]:gap-2 aspect-[3/2] rounded-xl grid grid-cols-[2fr_1fr_1fr]"
      >
        <div
          className="w-full bg-center bg-no-repeat bg-cover aspect-auto rounded-none row-span-2"
          style={{
            backgroundImage: `url(${URL.createObjectURL(item)})`,
          }}
        ></div>
        <div className="flex flex-col gap-1 p-2">
          <p className="text-white text-base font-medium leading-normal">
            {item.name}
          </p>
          <p className="text-[#9dabb8] text-sm font-normal leading-normal">
            {Math.round(item.size / 1024)}KB {item.type}
          </p>
        </div>
      </div>
    ));
  }, [selectedImages]);

  return (
    <div
      className="relative flex size-full min-h-screen flex-col bg-[#111418] dark group/design-root overflow-x-hidden"
      style={{
        "--select-button-svg":
          "url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 width=%2724px%27 height=%2724px%27 fill=%27rgb(157,171,184)%27 viewBox=%270 0 256 256%27%3e%3cpath d=%27M181.66,170.34a8,8,0,0,1,0,11.32l-48,48a8,8,0,0,1-11.32,0l-48-48a8,8,0,0,1,11.32-11.32L128,212.69l42.34-42.35A8,8,0,0,1,181.66,170.34Zm-96-84.68L128,43.31l42.34,42.35a8,8,0,0,0,11.32-11.32l-48-48a8,8,0,0,0-11.32,0l-48,48A8,8,0,0,0,85.66,85.66Z%27%3e%3c/path%3e%3c/svg%3e')",
        fontFamily: "Inter, Noto Sans, sans-serif",
      }}
    >
      <div className="layout-container flex h-full grow flex-col">
        <div className="px-40 flex flex-1 justify-center py-5">
          <div className="layout-content-container flex flex-col max-w-[960px] flex-1">
            <div className="flex flex-wrap justify-between gap-3 p-4">
              <div className="flex min-w-72 flex-col gap-3">
                <p className="text-white text-4xl font-black leading-tight tracking-[-0.033em]">
                  Upload Data
                </p>
                <p className="text-[#9dabb8] text-base font-normal leading-normal">
                  Add your data to a dataset
                </p>
              </div>
              <div className="flex justify-stretch">
                <div className="flex flex-1 gap-3 flex-wrap px-4 py-3 justify-end">
                  <button className="flex min-w-[84px] max-w-[480px] cursor-pointer items-center justify-center overflow-hidden rounded-xl h-10 px-4 bg-[#293038] text-white text-sm font-bold leading-normal tracking-[0.015em]">
                    <span className="truncate">Cancel</span>
                  </button>
                  <button
                    onClick={onSubmit}
                    className="flex min-w-[84px] max-w-[480px] cursor-pointer items-center justify-center overflow-hidden rounded-xl h-10 px-4 bg-[#1980e6] text-white text-sm font-bold leading-normal tracking-[0.015em]"
                  >
                    <span className="truncate">Upload</span>
                  </button>
                  {loading && (
                    <>
                      <Spinner />
                      <ProgressBar progress={progress} />
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="flex max-w-[480px] flex-wrap items-end gap-4 px-4 py-3">
              <label className="flex flex-col min-w-40 flex-1">
                <p className="text-white text-base font-medium leading-normal pb-2">
                  Category
                </p>
                <select
                  multiple
                  onChange={onSelectCategory}
                  className="form-input flex w-full min-w-0 flex-1 resize-none overflow-auto rounded-xl text-white focus:outline-0 focus:ring-0 border border-[#3c4753] bg-[#1c2126] focus:border-[#3c4753] h-14 placeholder:text-[#9dabb8] p-[15px] text-base font-normal leading-normal appearance-none"
                  style={{
                    backgroundImage: "none",
                    height: "auto",
                    maxHeight: "200px",
                  }} // Set maxHeight for scrolling
                >
                  <option value="" disabled>
                    Select a category
                  </option>
                  {categories.map((cat: Category) => (
                    <option key={cat._id} value={cat._id}>
                      {cat.name}
                    </option>
                  ))}
                </select>
              </label>
              <label className="flex flex-col min-w-40 flex-1">
                <p className="text-white text-base font-medium leading-normal pb-2">
                  Creator
                </p>
                <select
                  onChange={onSelectCreator}
                  className="form-input flex w-full min-w-0 flex-1 resize-none overflow-auto rounded-xl text-white focus:outline-0 focus:ring-0 border border-[#3c4753] bg-[#1c2126] focus:border-[#3c4753] h-14 placeholder:text-[#9dabb8] p-[15px] text-base font-normal leading-normal appearance-none"
                  style={{
                    backgroundImage: "none",
                    height: "auto",
                    maxHeight: "200px",
                  }} // Set maxHeight for scrolling
                  value={selectedCreator}
                >
                  <option value="" disabled>
                    Select a creator
                  </option>
                  {creators.map((cat: Category) => (
                    <option key={cat._id} value={cat._id}>
                      {cat.name}
                    </option>
                  ))}
                </select>
              </label>
            </div>

            <div className="flex max-w-[480px] flex-wrap items-end gap-4 px-4 py-3">
              <label className="inline-flex items-center cursor-pointer">
                <span className="text-white text-base font-medium leading-normal ">
                  Premium
                </span>
                <input
                  type="checkbox"
                  checked={isPremium}
                  onChange={(e) => setIsPremium(e.target.checked)}
                  className="sr-only peer"
                />
                <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600 ms-3"></div>
              </label>
            </div>

            <div className="flex max-w-[480px] flex-wrap items-end gap-4 px-4 py-3">
              <label className="flex flex-col min-w-40 flex-1">
                <p className="text-white text-base font-medium leading-normal pb-2">
                  Tags
                </p>
                <textarea
                  value={tags}
                  onChange={(e) => setTags(e.target.value)}
                  placeholder="Add tags to help categorize your data"
                  className="form-input flex w-full min-w-0 flex-1 resize-none overflow-hidden rounded-xl text-white focus:outline-0 focus:ring-0 border border-[#3c4753] bg-[#1c2126] focus:border-[#3c4753] min-h-36 placeholder:text-[#9dabb8] p-[15px] text-base font-normal leading-normal"
                ></textarea>
              </label>
            </div>

            <button
              onClick={() => selectInputRef?.current?.click()}
              className="flex max-w-[480px] flex-wrap items-end gap-4 px-4 py-3 cursor-pointer items-center justify-center overflow-hidden rounded-xl h-10 bg-[#293038] text-white gap-2 text-sm font-bold leading-normal tracking-[0.015em] min-w-0 px-2.5"
            >
              <div
                className="text-white"
                data-icon="Plus"
                data-size="20px"
                data-weight="regular"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20px"
                  height="20px"
                  fill="currentColor"
                  viewBox="0 0 256 256"
                >
                  <path d="M136,136V88a8,8,0,0,0-16,0v48H88a8,8,0,0,0,0,16h32v48a8,8,0,0,0,16,0V152h48a8,8,0,0,0,0-16Z"></path>
                </svg>
              </div>
              <span className="truncate">Select Images</span>
            </button>
            <div className="flex w-full grow bg-[#111418] @container p-4">
              {imageComponent}
            </div>
          </div>
        </div>
      </div>
      <input
        ref={selectInputRef}
        type="file"
        multiple
        className="hidden"
        onChange={(e) => {
          const files = e.target.files;
          if (files) {
            setSelectedImages([...selectedImages, ...Array.from(files)]);
          }
        }}
      />
      <SuccessModal
        title="Success"
        message="Images uploaded successfully"
        setVisible={() => setSuccessModal(false)}
        visible={successModal}
      />
      <ErrorModal
        title="Error"
        message="An error occured while uploading images"
        setVisible={() => setError(false)}
        visible={error}
      />
    </div>
  );
};

export default UploadDataPage;
