const DOMAIN = "https://api.walltales.app"
export const BASE_URL = `${DOMAIN}/api/`;
export const IMAGE_BASE_URL = `${DOMAIN}/image/`;

export const fetchApi = async (path: string, method: string, body?: any) => {
  const token = sessionStorage?.getItem("authToken");

  const options = {
    method,
    headers: {
      "Content-Type": "application/json",
      Authorization: token ? `Bearer ${token}` : null,
    },
  };

  if (body) {
    options.body = JSON.stringify(body);
  }

  const response = await fetch(`${BASE_URL}${path}`, options);
  console.log(response);
  if (!response.ok) {
    throw new Error(response.statusText);
  }

  return response.json();
};


export const fetchMedia = async (path: string, formData: any, onProgress: (progressEvent: ProgressEvent) => void) => {
  const token = sessionStorage.getItem("authToken");

  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open("POST", `${BASE_URL}${path}`, true);
    
    if (token) {
      xhr.setRequestHeader("Authorization", `Bearer ${token}`);
    }

    // Track upload progress
    xhr.upload.onprogress = onProgress;

    xhr.onload = () => {
      if (xhr.status >= 200 && xhr.status < 300) {
        resolve(JSON.parse(xhr.response));
      } else {
        reject(new Error(xhr.statusText));
      }
    };

    xhr.onerror = () => reject(new Error("Network Error"));
    
    xhr.send(formData);
  });
};


