import React, { useEffect, useState } from "react";
import { searchImages } from "../../Services.tsx";
import { IMAGE_BASE_URL } from "../../Utils/Connection.tsx";
import { Image } from "../Images/Images.tsx";

const AddCollectionItem = ({ isModalOpen, setIsModalOpen, onAddItem }) => {
  const [searchText, setSearchText] = useState("");
  const [images, setImages] = useState([]);
  const [selectedImages, setSelectedImages] = useState([]);
  const [debounceTimeout, setDebounceTimeout] = useState<any>(null);

  const [options, setOptions] = useState({
    name: "",
    startingHour: 0,
    endingHour: 0,
    icon: "",
    suggestedImages: [],
    shuffled: false,
    frequency: "",
    interval: 0,
  });

  // Function to toggle modal visibility
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const searchForImage = async () => {
    if (searchText.trim() === "") {
      setImages([]); // Clear results if the search text is empty
      return;
    }
    // Call the searchImages function from Services.tsx
    const res = await searchImages(searchText, 0, 10);
    setImages(res);
  };

  useEffect(() => {
    // Clear previous timeout if the user types again within the debounce time
    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }

    // Set a new timeout
    const newTimeout = setTimeout(() => {
      searchForImage(); // Call the search after the debounce delay
    }, 500); // Delay in milliseconds (500ms = 0.5 seconds)

    // Save the new timeout to the state
    setDebounceTimeout(newTimeout);

    // Cleanup function to clear timeout when component unmounts or when searchText changes
    return () => clearTimeout(newTimeout);
  }, [searchText]);

  // Function to select/deselect an image
  const selectImage = (image) => {
    // Check if the image is already selected
    const isSelected =
      selectedImages.findIndex(
        (selectedItem) => selectedItem._id === image._id
      ) > -1;

    if (isSelected) {
      // If selected, remove it from the selectedImages array
      setSelectedImages((prev) =>
        prev.filter((selectedItem) => selectedItem._id !== image._id)
      );
    } else {
      // If not selected, add it to the selectedImages array
      setSelectedImages((prev) => [...prev, image]);
    }
  };

  // Function to add the selected images to the collection
  const addItem = () => {
    // Call the onAddItem function from the parent component
    const b = {
        id: parseInt(Math.random() * 1000),
      ...options,
      suggestedImages: selectedImages,
    };
    onAddItem(b);
    // Close the modal
    toggleModal();
  };

  return (
    <div className="relative flex flex-col min-h-screen bg-[#111418]">
      {/* Modal Structure */}
      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          {/* Modal container with max height and scrollable content */}
          <div className="relative bg-[#111418] text-white rounded-lg shadow-lg w-[95%] max-h-[90vh] overflow-y-auto p-6">
            <header className="flex items-center justify-between border-b border-solid border-b-[#293038] px-10 py-3">
              <button
                onClick={toggleModal}
                className="ml-auto text-white font-bold"
              >
                X
              </button>
            </header>

            {/* Modal Body */}
            <div className="px-40 py-5">
              {/* Set this div width to 95% */}
              <div className="flex flex-col w-[95%] mx-auto">
                <div className="text-[32px] font-bold mb-6">
                  Create new slot
                </div>

                <div className="mb-4">
                  <label className="block mb-2">Slot Name</label>
                  <input
                    type="text"
                    placeholder="Product launch"
                    className="w-full bg-[#293038] text-white p-4 rounded-xl"
                    onChange={(e) =>
                      setOptions({ ...options, name: e.target.value })
                    }
                  />
                </div>

                <div className="flex gap-4 mb-4">
                  <div className="flex-1">
                    <label className="block mb-2">Start hour</label>
                    <input
                      type="text"
                      placeholder="8:00"
                      className="w-full bg-[#293038] text-white p-4 rounded-xl"
                      onChange={(e) =>
                        setOptions({
                          ...options,
                          startingHour: parseInt(e.target.value),
                        })
                      }
                    />
                  </div>
                  <div className="flex-1">
                    <label className="block mb-2">End hour</label>
                    <input
                      type="text"
                      placeholder="9:00"
                      className="w-full bg-[#293038] text-white p-4 rounded-xl"
                      onChange={(e) =>
                        setOptions({
                          ...options,
                          endingHour: parseInt(e.target.value),
                        })
                      }
                    />
                  </div>
                </div>

                <div className="mb-4">
                  <label className="block mb-2">Frequency</label>
                  <input
                    placeholder="Every day"
                    className="w-full bg-[#293038] text-white p-4 rounded-xl"
                    onChange={(e) =>
                      setOptions({ ...options, frequency: e.target.value })
                    }
                  />
                </div>
                <div className="mb-4">
                  <label className="block mb-2">Interval</label>
                  <input
                    placeholder="Every day"
                    className="w-full bg-[#293038] text-white p-4 rounded-xl"
                    onChange={(e) =>
                      setOptions({
                        ...options,
                        interval: parseInt(e.target.value),
                      })
                    }
                  />
                </div>

                <div className="mb-4">
                  <label className="block mb-2">Search for images</label>
                  <div className="relative flex">
                    <span className="absolute inset-y-0 left-0 flex items-center pl-4">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24px"
                        height="24px"
                        fill="currentColor"
                        viewBox="0 0 256 256"
                      >
                        <path d="M229.66,218.34l-50.07-50.06a88.11,88.11,0,1,0-11.31,11.31l50.06,50.07a8,8,0,0,0,11.32-11.32ZM40,112a72,72,0,1,1,72,72A72.08,72.08,0,0,1,40,112Z"></path>
                      </svg>
                    </span>
                    <input
                      type="text"
                      placeholder="Search for images"
                      className="pl-12 w-full bg-[#293038] text-white p-4 rounded-xl"
                      onChange={(e) => setSearchText(e.target.value)}
                    />
                  </div>
                </div>

                <div className="grid grid-cols-2 gap-4">
                  {images.map((item: Image) => (
                    <div
                      onClick={() => selectImage(item)}
                      key={item._id} // Unique key
                      className="relative aspect-video bg-cover rounded-xl bg-no-repeat cursor-pointer"
                      style={{
                        backgroundImage: `url(${IMAGE_BASE_URL}${item?.image})`,
                      }}
                    >
                      {/* Overlay for selected images */}
                      {selectedImages.findIndex(
                        (selectedItem: any) => selectedItem._id === item._id
                      ) > -1 && (
                        <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="32px"
                            height="32px"
                            fill="white"
                            viewBox="0 0 256 256"
                          >
                            <path d="M229.66,218.34l-50.07-50.06a88.11,88.11,0,1,0-11.31,11.31l50.06,50.07a8,8,0,0,0,11.32-11.32ZM40,112a72,72,0,1,1,72,72A72.08,72.08,0,0,1,40,112Z"></path>
                          </svg>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
              <button
              onClick={() => addItem()}
              className="w-full bg-[#1980e6] text-white py-3 rounded-xl"
            >
              Add Item
            </button>
            </div>
           
          </div>
        </div>
      )}
    </div>
  );
};

export default AddCollectionItem;
