import { createBrowserRouter, RouterProvider } from "react-router-dom";
import HomePage from "./pages/Home/Home.tsx";
import Images from "./pages/Images/Images.tsx";
import UploadDataPage from "./pages/UploadImage/UploadImage.tsx";
import Header from "./Components/Header.tsx";
import AppCategoriesPage from "./pages/Categories/Categories.tsx";
import CreateCategoryPage from "./pages/Categories/AddCategory.tsx";
import LoginPage from "./pages/Login/Login.tsx";
import ProtectedLayout from "./Route/ProtectedRoute.tsx";
import Category from "./pages/Categories/Category.tsx";
import AddCollections from "./pages/Collections/AddCollections.tsx";
import Collections from "./pages/Collections/Collections.tsx";
import CreatorsPage from "./pages/Creators/Creators.tsx";
import AddCreator from "./pages/Creators/AddCreator.tsx";





const router = createBrowserRouter([
  {
    path: '/login',
    element: (
      <LoginPage />
    )
  },
  {
    path: "/",
    element: (
      <ProtectedLayout element={


        <div>
          <Header />
          <HomePage />
        </div>
      } />
    )
  },
  {
    path: "/images",
    element: (
      <ProtectedLayout element={
        <>
          <Header />
          <Images />
        </>


      }
      />
    )

  },
  {
    path: "/add-images",
    element: (
      <ProtectedLayout element={
        <>
          <Header />
          <UploadDataPage />
        </>

      }
      />
    )
  },

  {
    path: "/categories",
    element: (
      <ProtectedLayout element={
        <div>
          <Header />
          <AppCategoriesPage />
        </div>
      } />

    )
  },
  {
    path: "/add-category",
    element: (
      <ProtectedLayout element={
        <div>
          <Header />
          <CreateCategoryPage />
        </div>
      } />
    )
  },
  {
    path: "/category/:id",
    element: (
      <ProtectedLayout element={
        <div>
          <Header />
          <Category />
        </div>
      } />
    )
  },
  {
    path: "/add-collection",
    element: (
      <ProtectedLayout element={
        <div>
          <Header />
          <AddCollections />
        </div>
      } />
    )
  },
  {
    path: "/collections",
    element: (
      <ProtectedLayout element={
        <div>
          <Header />
          <Collections />
        </div>
      } />
    )
  },
  {
    path: "/creators",
    element: (
      <ProtectedLayout element={
        <div>
          <Header />
          <CreatorsPage />
        </div>
      } />
    )
  },
  {
    path: "/add-creator",
    element: (
      <ProtectedLayout element={
        <div>
          <Header />
          <AddCreator />
        </div>
      } />
    )
  }

]);

function App() {
  return (
    <RouterProvider router={router}>


    </RouterProvider>
  );
}

export default App;
