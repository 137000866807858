import React, { useEffect, useState } from "react";
import "./styles.css"; // Import your CSS file here
import { fetchApi } from "../../Utils/Connection.tsx";
import { useNavigate } from "react-router-dom";
import ErrorModal from "../../Components/ErrorModal.tsx";
import Spinner from "../../Components/Spinner.tsx";

const LoginPage = () => {
  const navigate = useNavigate();
  const [body, setBody] = useState({
    username: "",
    password: "",
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const onLogin = async () => {
    try {
      setLoading(true);
      const response = await fetchApi("admin/login", "POST", body);

      if (response?.token) {
        console.log(response.token);
        // Store the token in local storage
        sessionStorage.setItem("authToken", response.token);
        // Redirect to the home page
        navigate("/");
      } else {
        setError(true);
      }
    } catch (error) {
      setError(true);
    }
    setLoading(false);
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setBody({
      ...body,
      [e.target.name]: e.target.value,
    });
  };

  // Handle key press to submit form on Enter key
  const handleKeyDown = (e: React.KeyboardEvent<HTMLFormElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      onLogin(); // Trigger the login function when Enter is pressed
    }
  };

  return (
    <div className="container">
      <header>
        <div className="header-logo">
          <svg viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4 4H17.3334V17.3334H30.6666V30.6666H44V44H4V4Z" fill="currentColor"></path>
          </svg>
          <h2>WallTales</h2>
        </div>
      </header>

      <div className="layout-content">
        <h3>Log in to WallTales</h3>
        <form onKeyDown={handleKeyDown}>
          <div className="form-label">
            <label className="label">Username or email</label>
            <input
              type="text"
              placeholder="Username or email"
              name="username"
              value={body.username}
              onChange={onChange}
            />
          </div>
          <div className="form-label">
            <label className="label">Password</label>
            <input
              type="password"
              placeholder="Password"
              name="password"
              value={body.password}
              onChange={onChange}
            />
          </div>
          <p className="underline">Forgot your password?</p>
        </form>
        <button type="submit" onClick={onLogin} className="button">
          Log in
        </button>
        <p className="text-sm"></p>
        <p className="underline text-sm"></p>
      </div>
      {loading && <Spinner />}
      <ErrorModal
        visible={error}
        setVisible={setError}
        title="Error"
        message="Invalid credentials. Please try again."
      />
    </div>
  );
};

export default LoginPage;
